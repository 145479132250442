import React, { PureComponent } from 'react';
import { Avatar, FontIcon, DialogContainer, List, ListItem } from 'react-md';
import moment from 'moment';

import HeatmapCalendar from 'heatmap-calendar-react';
import 'heatmap-calendar-react/build/style.css';
import './heatmap.css';

import { toRoundNumberString } from '../../utils';


const prepareData = (data, prop, label, transform) =>  Object.keys(data).map(date => 
    ({
      date: moment(date).add(6, 'hour').toDate(),
      total: transform? 
        transform(data[date][prop]) :
        data[date][prop],
      name: label
    })
  )

const applyFilter = (data, filter) =>
    data.filter((x) => filter(x.total));


export class HourHeatmap extends PureComponent {
  
  constructor() {
    super();
    this.state = { visible: false, date: null, value: null };

    this.handleClick = this.handleClick.bind(this);
  }

  show = () => {
    this.setState({ visible: true });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  handleKeyDown = (e) => {
    const key = e.which || e.keyCode;
    if (key === 13 || key === 32) {
      // also close on enter or space keys
      this.hide();
    }
  };

  handleClick = (e, day) => {
    
    this.setState({
      date: moment(day.date).format('l'),
      value: day.desc.total,
      visible: true
    });
  }

  render() {

    const { visible, date, value } = this.state;

    const { data, prop, title, label, filter, transform } = this.props;

    //console.log(data)

    const parsed = filter? 
      applyFilter(prepareData(data, prop, label, transform), filter) :
      prepareData(data, prop, label, transform);

    return (
      <React.Fragment>
        <h4>{title}</h4>
        <div  id={prop}>
          <HeatmapCalendar
            id={prop}
            weekdayLabels={["D", "S", "T", "Q", "Q", "S", "S"]}
            receiveDates={dates => ({})} // perevents warnings
            keyNavigation={true}
            onClick={this.handleClick}
            //showTooltip={true}
            addGap={true}
            data={parsed}
            //showDate={true}
        />
        </div>
        <DialogContainer
          id="date-dialog-container"
          visible={visible}
          title={`Horas ${label} - ${date}`}
          onHide={this.hide}
          width={400}
          focusOnMount={false}
        >
          <List onClick={this.hide} onKeyDown={this.handleKeyDown}>
            {(!!value || value === 0) && <ListItem 
              primaryText={toRoundNumberString(value, 1) + " horas"}
              leftAvatar={<Avatar icon={<FontIcon>hourglass_empty</FontIcon>} />}
            />}
          </List>
        </DialogContainer>
      </React.Fragment>
    )
  }
}