import React from 'react';

import { Grid, Card, CardTitle, CardText, Cell, List, ListItem, Button } from 'react-md';

import * as licenses from "./licenses.json"

export const About = () => (
  <Grid>
    <Cell size={12}>
    <Card>
    <CardTitle
      title="Sistema RH KV"
      subtitle={`Versão ${process.env.REACT_APP_VERSION}`}
    />
    <CardTitle
      title="Bibliotecas utilizadas e respectivas licenças de uso:"
      expander
    />
   
    <CardText expandable>
      
      <List>
        {
          Object.keys(licenses.default).map(dependency =>
            (<ListItem 
              key={dependency}
              primaryText={dependency}
              secondaryText={licenses.default[dependency].licenses}
              renderChildrenOutsid
            >
              <Button icon href={licenses.default[dependency].repository} primary>public</Button>
            </ListItem>)
          )
        }
      </List>
      </CardText>
      </Card>
    </Cell>
  </Grid>
)