import * as types from './types';

export const getBHUser = (bh => ({type: types.FETCH_BH_USER, bh}));

export const getBHAll = (bh => ({type: types.FETCH_BH_ALL, bh}));

export const getDailyUser = (daily => ({type: types.FETCH_DAILY_USER, daily}));

export const getVacationsUser = (vacations => ({type: types.FETCH_VAC_USER, vacations}));
export const getVacationsAll = (vacations => ({type: types.FETCH_VAC_ALL, vacations}));


export const getContractsUser = (contracts => ({type: types.FETCH_CONTRACTS_USER, contracts}));