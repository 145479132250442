import React, { PureComponent } from 'react';
import { Card, TableCardHeader, Button, DataTable as MaterialDataTable, TableBody, TableColumn, TableHeader, TableRow } from 'react-md';
import { withRouter } from 'react-router-dom';

import { toRoundNumberString } from '../utils';

import { buRevEnum } from '../enum';

import "./data-table.css";

const conditionalValidation = (validation, value) =>
  validation(value) ? 'column-good' : 'column-bad';

const columnClass = (column, value) => {
  const classes = [
    column.conditional ? conditionalValidation(column.conditional, value): '',
    column.class ? column.class : ""
  ];
  return classes.join(" ");
}


const headerColumnComponent = (column) => {
  switch(column.type) {
    case "numeric":
      return (<TableColumn key={column.key} header numeric>{column.label}</TableColumn>);
    default:
      return (<TableColumn key={column.key} header>{column.label}</TableColumn>);
  }
}

const dataColumnComponent = (column, row, history) => {
  switch(column.type) {
    case "numeric":
      return (
        <TableColumn key={column.key} numeric className={columnClass(column, row[column.key])}>
          { toRoundNumberString(row[column.key], 1) }
        </TableColumn>
      );
    case "url_button":
      return (<TableColumn key={column.key} className={column.class? column.class: ""}>
        <Button onClick={() => history.push(row[column.key].url)} icon>{row[column.key].icon}</Button>
      </TableColumn>);
    case "action_button":
      return (<TableColumn key={column.key} className={column.class? column.class: ""}>
        <Button onClick={row[column.key].action} icon>{row[column.key].icon}</Button>
      </TableColumn>);
    default:
      return (<TableColumn key={column.key} className={column.class? column.class: ""}>{row[column.key]}</TableColumn>);
  }
}



class BaseDataTable extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      visibleRows: props.dataRows.map(row => true),
      filterGD: false,
      filterGT: false
    }

    this.toggleGD = this.toggleGD.bind(this);
    this.toggleGT = this.toggleGT.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.dataRows.length !==prevState.visibleRows.length){
      return { visibleRows: nextProps.dataRows.map(row => true)};
    }
    else return null;
  }

  // TODO: remove hardcoding; unify methods

  toggleGD() {

    let visibleRows;
    let { filterGD, filterGT } = this.state;

    filterGD?
      visibleRows = this.props.dataRows.map(row => true) :
      visibleRows = this.props.dataRows.map(
        row => row.bu === buRevEnum.gd
      );

    filterGD = !filterGD;
    filterGT = false
    
    this.setState({ visibleRows: visibleRows, filterGD, filterGT });

  }

  toggleGT() {

    let visibleRows;
    let { filterGT, filterGD } = this.state;

    filterGT?
      visibleRows = this.props.dataRows.map(row => true) :
      visibleRows = this.props.dataRows.map(
        row => row.bu === buRevEnum.gt
      );

    filterGD = false;
    filterGT = !filterGT;
    
    this.setState({ visibleRows: visibleRows, filterGT, filterGD });

  }

  render() {
    const { dataRows, dataColumns, history, title} = this.props;
    const { visibleRows, filterGD, filterGT } = this.state;

    const cols = dataColumns.map(headerColumnComponent);


    return (
      <Card>
        <TableCardHeader
          title={title}
          visible={false}
        >
          <Button flat onClick={this.toggleGT} primary swapTheming={filterGT}>Gerência Técnica</Button>
          <Button flat onClick={this.toggleGD} primary swapTheming={filterGD}>Gerência de Design</Button>

        </TableCardHeader>
        <MaterialDataTable baseId="data-table" plain>
          <TableHeader>
            <TableRow>
              {cols}
            </TableRow>
          </TableHeader>
          <TableBody>
            {
              dataRows.map((row, index) => ( 
                <TableRow key={row.key} style={{display: visibleRows[index]? '': "none" }}>
                  {dataColumns.map((column) => dataColumnComponent(column, row, history)) }
                </TableRow>
              ))
            }
          </TableBody>
        </MaterialDataTable>
      </Card>
    );
  }
}


export const DataTable = withRouter(BaseDataTable);