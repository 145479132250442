import { combineReducers } from 'redux';
import { allBHReducer, userBHReducer } from './bh';
import { userContractsReducer } from './contracts';
import { userDailyReducer } from './daily';
import { sessionReducer } from './session';
import { allVacationsReducer, userVacationsReducer } from './vacations';
import { userReducer } from './user';

const rootReducer = combineReducers({
  sessionState: sessionReducer,
  userBHState: userBHReducer,
  allBHState: allBHReducer,
  userVacationsState: userVacationsReducer,
  allVacationsState: allVacationsReducer,
  userContractsState: userContractsReducer,
  userDailyState: userDailyReducer,
  userState: userReducer
});

export default rootReducer;