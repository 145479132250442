import React from 'react';
import { FontIcon, TabsContainer, Tabs, Tab, Toolbar } from 'react-md';

import "./tab-layout.css";


const scrollToTop = (activeTabIndex, tabId, tabPanelId) => {

  const panel = document.getElementById(tabPanelId);
  if (panel) {
    panel.parentNode.scrollTop = 0;
  }
}

export const TabLayout = (props) => (

    <TabsContainer
      fixed
      labelAndIcon
      toolbar={<Toolbar title={props.title}/>}
      onTabChange={scrollToTop}
    >
    <Tabs style={({marginTop: 0})}
      tabId="dashboard"
      activeTabClassName="md-tab--active md-text--secondary"
      inactiveTabClassName="md-text--secondary"
      >
      {props.tabs.map(tab => (
        <Tab key={tab.label} label={tab.label} icon={<FontIcon>{tab.icon}</FontIcon>}>
          {tab.component}
        </Tab>)
      )}
    </Tabs>
  </TabsContainer>

);