/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, DialogContainer, Toolbar, DatePicker, Slider } from 'react-md';

const Indicator = ({ children }) => <span className="md-slider-ind md-text">{children}</span>;

export class AddContractsDialog extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    addContract: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      date: new Date(),
      hoursPerDay: 8.8,
      multiplier: 1.5,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }
 
  // TODO: unsafe method
  componentWillReceiveProps(nextProps) {
    if (this.props.visible && this.props.visible !== nextProps.visible) {
      this.setState({ count: 1 });
    }
  }


  handleSubmit = (e) => {
    e.preventDefault();
    const { hoursPerDay, multiplier, date } = this.state;
    this.props.addContract(date, hoursPerDay, multiplier);
    this.props.onHide();
  };

  updateDate = (dateString, date) => {
    this.setState({ date });
  }

  updateHoursPerDay = (hoursPerDay) => {
    this.setState({ hoursPerDay });
  };

  updateMultiplier = (multiplier) => {
    this.setState({ multiplier });
  };

  render() {
    const { visible, onHide } = this.props;

    const { date, hoursPerDay, multiplier } = this.state;

    return (
      <DialogContainer
        id="add-contracts-dialog"
        aria-labelledby="add-contracts-dialog-title"
        visible={visible}
        onHide={onHide}
        fullPage
      >
        
        <form
          component="form"
          onSubmit={this.handleSubmit}
        >
   
          <Toolbar
            nav={<Button icon onClick={onHide}>arrow_back</Button>}
            title="Adicionar contratos"
            titleId="add-contracts-dialog-title"
            fixed
            colored
            actions={<Button type="submit" flat>Enviar</Button>}
          />
            <section className="md-grid" aria-labelledby="contract-title">
            <h2 id="contract-title" className="md-cell md-cell--12">
              "Novo contrato"
            </h2>

            <DatePicker
              id="date"
              label="Data de vigência"
              className="md-cell md-cell--12"
              value={date}
              onChange={this.updateDate}
              required
              autoOk
            />

            <Slider
              id="hours-per-day"
              min={0}
              step={0.2}
              max={24}
              editable
              value={hoursPerDay}
              onChange={this.updateHoursPerDay}          
              leftIcon={<Indicator>Horas por dia</Indicator>}
              className="md-cell md-cell--12"
            />

            <Slider
              id="multiplier"
              min={0}
              max={3}
              step={0.1}
              editable
              value={multiplier}
              onChange={this.updateMultiplier}
              leftIcon={<Indicator>Multiplicador</Indicator>}
              className="md-cell md-cell--12"
              />
          </section>    

        </form>
      </DialogContainer>
    );
  }
}