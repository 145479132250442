import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import createHistory from "history/createBrowserHistory";
import { compose } from 'recompose';
import './App.css';
import { withAuthentication } from './auth';
import { BankedHoursDetails, BankedHoursOverview } from './banked-hours';
import { BankedHoursDashboard, VacationsDashboard } from './dashboards';
import { Forbidden, NotFound } from './errors';
import { NavDrawer } from './nav';
import * as routes from './routes';
import { SignIn } from './sign-in';
import UserVacations from './vacations';
import { UserAdd, UserEdit } from './user';
import { ContractView, ContractEditTable } from './contracts';
import { About } from './about';


const history = createHistory();

class App extends Component {
  render() {
    return (
      <Router history={history}><React.Fragment>
        <NavDrawer>
          <Switch>
            <Route path={routes.LANDING} exact render={(props) => (
              !this.props.authUser ? (<Redirect to={routes.SIGN_IN}/>) : 
              this.props.authUser.isAdmin ? (<Redirect to={routes.BH_DASHBOARD}/>) :
              (<Redirect to={routes.BH_OVERVIEW}/>)
            )} />
            <Route path={routes.BH_OVERVIEW} exact render={(props) => (
              !this.props.authUser ? (<Redirect to={routes.SIGN_IN}/>) : (<BankedHoursOverview {...this.props} {...props} />)
            )} />
            <Route path={routes.BH_USER_OVERVIEW} exact render={(props) => (
              !this.props.authUser ? (<Redirect to={routes.SIGN_IN}/>) : (<BankedHoursOverview {...this.props} {...props} />)
            )} />
            <Route path={routes.BH_DETAILS } exact render={(props) => (
              !this.props.authUser ? (<Redirect to={routes.SIGN_IN} />) : (<BankedHoursDetails {...this.props} {...props} />)
            )} />
            <Route path={routes.BH_USER_DETAILS } exact render={(props) => (
              !this.props.authUser ? (<Redirect to={routes.SIGN_IN} />) : (<BankedHoursDetails {...this.props} {...props} />)
            )} />

            <Route path={routes.VACATIONS_OVERVIEW} exact render={(props) => (
              !this.props.authUser ? (<Redirect to={routes.SIGN_IN}/>) : (<UserVacations {...this.props} {...props} />)
            )} />
            <Route path={routes.VACATIONS_USER_OVERVIEW} exact render={(props) => (
              !this.props.authUser ? (<Redirect to={routes.SIGN_IN}/>) : (<UserVacations {...this.props} {...props} />)
            )} />

            <Route path={routes.BH_DASHBOARD} exact render={(props) => (
              !this.props.authUser ? (<Redirect to={routes.SIGN_IN}/>) : (<BankedHoursDashboard {...this.props} {...props} />)
            )} />

            <Route path={routes.VACATIONS_DASHBOARD} exact render={(props) => (
              !this.props.authUser ? (<Redirect to={routes.SIGN_IN}/>) : (<VacationsDashboard {...this.props} {...props} />)
            )} />

            <Route path={routes.USER_ADD} exact render={(props) => (
              !this.props.authUser ? (<Redirect to={routes.SIGN_IN}/>) : (<UserAdd {...this.props} {...props} />)
            )} />

            <Route path={routes.USER_EDIT} exact render={(props) => (
              !this.props.authUser ? (<Redirect to={routes.SIGN_IN}/>) : (<UserEdit {...this.props} {...props} />)
            )} />

            <Route path={routes.CONTRACT_USER} exact render={(props) => (
              !this.props.authUser ? (<Redirect to={routes.SIGN_IN}/>) : (<ContractView {...this.props} {...props} />)
            )} />

            <Route path={routes.CONTRACT_USER_EDIT} exact render={(props) => (
              !this.props.authUser ? (<Redirect to={routes.SIGN_IN}/>) : (<ContractEditTable {...this.props} {...props} />)
            )} />

            <Route path={routes.ABOUT} exact render={(props) => (
              !this.props.authUser ? (<Redirect to={routes.SIGN_IN}/>) : (<About {...this.props} {...props} />)
            )} />

            

           <Route path={routes.FORBIDDEN} render={(props) => (<Forbidden  {...this.props} {...props} />)} />

            <Route component={NotFound} />         
          </Switch>

        </NavDrawer>

        <Route path={routes.SIGN_IN} exact render={(props) => (
          (<SignIn {...this.props} {...props} />)
        )} /></React.Fragment>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});


export default compose(
  withAuthentication,
  connect(mapStateToProps)
)(App);