import React from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { toRoundNumberString } from '../../../utils';


const prepareData = (data) =>
  Object.keys(data).map(period => 
    ({
      "name": period,
      "Acumulado": data[period].accrued
    })
  )

const color = "#1EB980";

const tooltipStyle = { backgroundColor: '#424242' };
const tooltipFormatter = (value) => 
  toRoundNumberString(value, 1);

export const PeriodBarChart = (props) => (
  <ResponsiveContainer width="100%" height={300}>
    <BarChart data={prepareData(props.data)}
          margin={{top: 20, right: 30, left: 20, bottom: 5}}>
    <CartesianGrid strokeDasharray="3 3"/>
    <XAxis dataKey="name" stroke="#fff"/>
    <YAxis stroke="#fff"/>
    <Tooltip wrapperStyle={tooltipStyle} formatter={tooltipFormatter}/>
    <Bar dataKey="Acumulado" stackId="a" fill={color} />
    </BarChart>
  </ResponsiveContainer>
)