import React from 'react';

import "./help.css";


const renderElement = (element) => 
  element.subheader ?
    <h4 className="md-text-uppercase help-title">{element.text}</h4> :
    <p><span className="help-prop">{element.label}</span> {element.text}</p>

export const Help = (props) => (
  <React.Fragment>
    <div className="help">
      {props.content.map(renderElement)}
    </div>
  </React.Fragment>
)