import React from 'react';
import moment from 'moment';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './contract-element.css';
import { EventIcon } from '../../../components';

export const ContractElement = (props) => (

  <VerticalTimelineElement
    className="vertical-timeline-element--contract"
    iconStyle={{ background: "#f75500", color: '#fff' }}
    icon={<EventIcon className="md-inline-block" inherit>event</EventIcon>}
  >
    <h3 className="vertical-timeline-element-title">Vigência a partir de {moment(props.date).format('l')}</h3>
    <p className="contract-element-text">Expediente: {props.hoursPerDay}</p>
    <p className="contract-element-text">Multiplicador: {props.multiplier}</p>

  </VerticalTimelineElement>
)
