import React from 'react';

import {IconSeparator, List, ListItem, Divider, Card, CardTitle, CardText, FontIcon } from 'react-md';

import { toRoundNumberString } from '../../../utils';

import "./expandable-card.css"

const conditionalClass = (value) => 
  value < 0 ? "md-display-1 expandable-title-bad" : "md-display-1 expandable-title-good"

const cardTitle = (text, icon, value) => (
  <span>
    <FontIcon>{icon}</FontIcon> {text}<span className={conditionalClass(value)}>
      {toRoundNumberString(value, 1)}
    </span>
  </span>
)

const itemValue = (icon, value) => 
  <IconSeparator label={toRoundNumberString(value, 1)} iconBefore>
    <FontIcon>{icon}</FontIcon>
  </IconSeparator>




export const ExpandableCard = (props) => (
  <Card>
    <CardTitle
      className="expandable-card-title"
      title={cardTitle(props.title.label, props.title.icon, props.title.value)}
      expander
    />
    
    <CardText expandable>
      <List>
        {props.body.map(item =>
          item.divider? (<Divider key={item.label} />) :
          (<ListItem key={item.label} primaryText={item.label} rightIcon={itemValue(item.icon, item.value)} />)
        )}
      </List>
    </CardText>
    { props.children }
  </Card>
)


