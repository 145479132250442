import React from 'react';

import { Collapse } from 'react-md';
import { Help } from '../../../help';


const helpContent = [
  {
    subheader: true,
    text: "Dados do usuário"
  },
  {
    label: "Nome:",
    text: "nome do usuário utilizado nas tabelas dos dashboards administrativos."
  },
  {
    label: "email:",
    text: "email do usuário. Deve ser um email válido no domínio da KV. Não incluir o @kvarch.com ao preencher o campo."
  },
  {
    label: "Gerência:",
    text: "de qual gerência o usuário faz parte. Utilizado somente para fins de filtro na interface administrativa."
  },  
  {
    label: "É administrador do sistema:",
    text: "se assinalada, o usuário possuirá acesso ao sistema como administrador, podendo visualizar os dashboards com os dados de todos os usuários e criar novos usuários"
  },  
  {
    label: "Data de férias:",
    text: "data de referência para cálculo de férias."
  },
  {
    subheader: true,
    text: "Dados do contrato"
  },
  {
    label: "Data de ingresso:",
    text: "data na qual o usuário iniciou o período de experiência na empresa. O primeiro contrato do usuário para fins de banco de horas é nesta data."
  },
  {
    label: "Horas por dia:",
    text: "quantas horas por dia o usuário precisa trabalhar em média (o expediente). Para a maior parte dos usuários este campo deverá ser 8,8 (regime integral) ou 6 (regime parcial ou estágio)."
  },
  {
    label: "Multiplicador:",
    text: "qual o fator que multiplica eventuais saldos positivos ao término do trimestre para adição ao banco de horas. Para a maior parte dos usuários este campo deverá ser 1,5 ou 1"
  }
]


export const HelpBox = (props) => (
  <Collapse collapsed={props.collapsed}>
    <Help content={helpContent} />
  </Collapse>

)