import moment from 'moment';
import "moment/locale/pt-br";
import React, { Component } from 'react';
import BigCalendar from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendar.css';
import { EventDialog } from './event-dialog';

moment.locale('pt-br');

BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment))

const messages = {
  allDay: "Dia inteiro",
  previous: "Anterior",
  next: "Próximo",
  today: "Hoje",
  month: "Mês",
  week: "Semana",
  day: "Dia",
  date: "Data",
  time: "Hora",
  event: "Evento",
}

const COLORS = [
  "secondary",
  "orange-50",
  "orange-300",
  "yellow-300",
  "yellow-500",
  "purple-50",
  "purple-200",
  "blue-200",
  "blue-700",
  "green-300",
  "green-700",
  "teal-700"
]


const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

const colors = shuffleArray(COLORS);

const index = (text, length) => 
  text? Math.floor(length * text.charCodeAt(0)/128) : 0;

const eventClass = (event, start, end, isSelected) =>
  ({ className: colors[index(event.resource, colors.length)] })

const flatten = (arr) => arr.reduce((flat, next) => flat.concat(next), []);

const parseEvents = (user, events) => 

  events?
    events.map(event => {
      const start = moment(event.date);
      const end = moment(start).add(event.duration, 'days')
      return {
        title: `Férias ${user}`,
        start: start,
        end: end,
        allDay: true,
        resource: user
      }
    })
  : [];


export class VacationsCalendar extends Component {

  constructor(props) {
    super(props)
    this.state = {
       isDialogOpen: false,
       eventTitle: 'Teste', 
       eventStart: null, 
       eventEnd: null
    }

    this.closeEventDialog = this.closeEventDialog.bind(this);
 }

  openEventDialog(event) {
    this.setState({
       isDialogOpen: true,
       eventTitle: event.title,
       eventStart: event.start,
       eventEnd: event.end
    })
  }

  // use even binding instead of global state since redux would be overkill for this
  closeEventDialog() {
    this.setState({
      isDialogOpen: false
    })
  }

  render() {
    const { data } = this.props;

    const events = flatten(data.map(userData => parseEvents(userData.user, userData.events)));

    return (
      <React.Fragment>

        <BigCalendar
          selectable
          events={events}
          defaultView="month"
          scrollToTime={new Date(1970, 1, 1, 6)}
          defaultDate={new Date()}
          onSelectEvent={eventInfo => this.openEventDialog(eventInfo)}
          eventPropGetter={eventClass}
          messages={messages}
        />
        <EventDialog
          visible={this.state.isDialogOpen}
          onCloseDialog={this.closeEventDialog}
          title={this.state.eventTitle}
          start={this.state.eventStart}
          end={this.state.eventEnd}
        />
      </React.Fragment>
    )
  }
}