import React, { PureComponent } from 'react';
import { NavigationDrawer } from 'react-md';
import { withRouter } from 'react-router';
import { withUserAuthorization } from '../auth';
import * as routes from "../routes";
import { UserAvatar } from "./avatar";
import { NavItem } from "./nav-item";

import { simpleTitles, paramTitles, extractBaseUrl, extractParam } from './route-titles';

const styles = {
  content: { minHeight: 'auto' },
};


const userNavItems = [
  {
    label: 'Banco de Horas',
    to: routes.BH_OVERVIEW,
    exact: true,
    icon: 'timer',
  },
  {
    label: 'Férias',
    to: routes.VACATIONS_OVERVIEW,
    exact: true,
    icon: 'event',
  },
  {
    label: 'Cadastrar licença',
    to: routes.LICENSE_FORM,
    exact: true,
    icon: 'healing',
  }
];

const adminNavItems = [
  {
    label: 'Banco de Horas',
    to: routes.BH_DASHBOARD,
    exact: true,
    icon: 'timer',
  },
  {
    label: 'Férias',
    to: routes.VACATIONS_DASHBOARD,
    exact: true,
    icon: 'event',
  },
  {
    label: 'Criar Usuário',
    to: routes.USER_ADD,
    exact: true,
    icon: 'person_add',
  },
  {
    label: 'Cadastrar licença',
    to: routes.LICENSE_FORM,
    exact: true,
    icon: 'healing',
  }
];


class NavDrawer extends PureComponent {

  state = {
    navItems: null,
    title: "Sistema de RH KV"
  }

  // TODO: unsafe?
  UNSAFE_componentWillMount() {

    if(this.props.authUser) {
      this.props.authUser.isAdmin ? 
        this.setState({ navItems: adminNavItems}) :
        this.setState({ navItems: userNavItems });
    }
    else {
      this.setState({ navItems: [] });
    }

    this.setState({ title: this.getTitle(this.props.location.pathname)})
    
  }

  componentDidUpdate() {
    this.setState({ title: this.getTitle(this.props.location.pathname)})
  }

  getTitle = (path) =>     
    simpleTitles.hasOwnProperty(path) ?
      simpleTitles[path] :
      this.getParamTitle(path)
  

  getParamTitle = (path) => 
    (paramTitles[extractBaseUrl(path)] + extractParam(path))

  render() {
    return (
      this.props.authUser ? 
        <NavigationDrawer
          toolbarThemeType="themed"
          drawerTitle="Navegação"
          toolbarTitle={this.state.title}
          mobileDrawerType={NavigationDrawer.DrawerTypes.TEMPORARY_MINI}
          tabletDrawerType={NavigationDrawer.DrawerTypes.PERSISTENT_MINI}
          desktopDrawerType={NavigationDrawer.DrawerTypes.PERSISTENT_MINI}
          navItems={this.state.navItems.map(props => <NavItem {...props} key={props.to} />)}
          contentId="main-content"
          contentStyle={styles.content}
          contentClassName="md-grid"
          toolbarActions={<UserAvatar authUser={this.props.authUser} />}
        >
          {this.props.children}
        </NavigationDrawer>
       :
        <div>{this.props.children}</div>
      )
  }
}

export default withUserAuthorization(withRouter(NavDrawer));
