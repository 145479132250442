import React from 'react';
import { Card, CardTitle, CardText } from 'react-md';
import { Bar, BarChart, CartesianGrid, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { sortBy, toRoundNumberString } from '../../utils';


const tooltipStyle ={backgroundColor: '#424242' };

const tooltipFormat = (value) => 
  toRoundNumberString(value, 1);


const topN = (data, dataProp, isAscending, n) => {

  const sourceData = data.concat().sort(sortBy(dataProp));

  if(!isAscending) {
    sourceData.reverse();
  }

  return sourceData.slice(0, n);
}


export const TopNCard = (props) => {

  const sourceData = topN(props.data, props.dataProp, props.ascending, props.n);  

  return(
  <Card>
    <CardTitle title={props.title}></CardTitle>
    <CardText>
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        layout="vertical"
        data={sourceData}
        margin={{top: 20, right: 30, left: 20, bottom: 5}}
      >
        <CartesianGrid strokeDasharray="3 3"/>
        <ReferenceLine x={0} stroke="white"/>
        <YAxis dataKey={props.categoryProp} stroke="#fff" type="category" />
        <XAxis stroke="#fff" type="number" />
        <Tooltip wrapperStyle={tooltipStyle} formatter={tooltipFormat}/>
        <Bar dataKey={props.dataProp} fill={props.color} />
      </BarChart>
      </ResponsiveContainer>

    </CardText>
  </Card>
)}