import React from 'react';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './period-detail-element.css';
import { StackedBarChart } from "./stacked-bar-chart";
import { EventIcon } from '../../../../components';
import { toRoundNumberString } from '../../../../utils';


const verb = (value) =>
  value >= 0 ? "adiciona" : "subtrai";

const plural = (value) =>
  value === 1 ? "" : "s";

const format = (value) =>
  toRoundNumberString(value, 1) + " hora" + plural(value)

const formatPeriod = (period) => 
  period.slice(-2) + "/" + period.slice(0, 4);


export const PeriodDetailElement = (props) => (
  <VerticalTimelineElement
    className="vertical-timeline-element--period"
    iconStyle={{ background: "#f75500", color: '#fff' }}
    icon={<EventIcon className="md-inline-block" inherit>event</EventIcon>}
  >
    <h3 className="vertical-timeline-element-title">Detalhamento fechamento {formatPeriod(props.period)}</h3>
    <StackedBarChart {...props}></StackedBarChart>
    <h4>Saldo nos dias úteis: {format(props.data.balance)} ({verb(props.data.toAccrue)} {
      props.data.balance > 0 ? format(props.data.toAccrue) : format(-props.data.balance)
      })</h4>
    <h4>Horas Toggl em FDS e feriados: {format(props.data.nwdWorked)} (adiciona {format(props.data.nwdAccrued)})</h4>
    <h4>Saldo do período anterior: {format(props.data.previousPeriod)} ({verb(props.data.previousPeriod)} {
      props.data.previousPeriod > 0 ? format(props.data.previousPeriod) : format(-props.data.previousPeriod)
    })</h4>
    <hr />
    <h3><b>Resultado:</b> {format(props.data.accrued)}</h3>
  </VerticalTimelineElement>
)