const  replaceAll = (str, find, replace) => {
  return str.split(find).join(replace);}

export const replaceCommasWithDots = (str) => 
  replaceAll(str, ',', '.');

export const replaceDotsWithCommas = (str) => 
  replaceAll(str, '.', ',');

export const toISODateString = (date) => 
  date.toISOString().substring(0, 10);