import React from 'react';
import { Grid, Cell } from 'react-md';

import { TopNCard } from './top-n-card';


export const SummaryLayout = (props) => (
  <Grid>
    {
      props.content.map(card => (
        <Cell size={6} key={card.title}>
          <TopNCard
            data={props.data}
            title={card.title}
            color={card.color}
            categoryProp={card.categoryProp}
            dataProp={card.dataProp}
            ascending={card.ascending}
            n={card.quantity}
          />
        </Cell>
      ))
    }
  </Grid>
)