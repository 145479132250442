import { FETCH_BH_ALL } from '../../actions/types';

const INITIAL_STATE = {
  allBH: {},
};

const applySetBH = (state, action) => ({
  ...state,
  allBH: action.bh
});

function allBHReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case FETCH_BH_ALL: {
      return applySetBH(state, action);
    }
    default : return state;
  }
}

export default allBHReducer;