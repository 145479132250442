/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import { onceGetUserVac } from '../../actions';
import { Button, DialogContainer, Toolbar, DatePicker } from 'react-md';

import { toISODateString } from '../../utils';


export class DatesDialog extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    updateDates: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    onHide: PropTypes.func.isRequired,
  };

  _email = null;

  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      hireDate: null,
      email: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  } 


  /*static getDerivedStateFromProps(nextProps, prevState) {

    return (
        (prevState.email === null ||  prevState.email === '') && 
        (nextProps.email !== null && nextProps.email !== '')
      ) ? 
      { email: nextProps.email }
      : null;
  }*/

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.email !== prevProps.email) &&
      (this.state.email === null)
    //  (prevState.email === null ||  prevState.email === '') && 
   //   (prevProps.email !== null && prevProps.email !== '')
    ) {
      this._loadAsync(this.props.email);
    }
  }

  componentWillUnmount() {
    this._email = null;
  }

  _loadAsync(email) {

    if (email !== this._email) {

      this._email = email;

    
    onceGetUserVac(email).then(snapshot =>
      {
        const vac = snapshot.val();

        // Only update state after promise has resolved and if the request is still valid
        if(email === this._email) {
          this.setState({    
            hireDate: moment(vac.hireDate).toDate(),
            startDate: moment(vac.startDate).toDate()
          });
        }
      })
  }
  
  }


  handleSubmit = (e) => {
    e.preventDefault();
    const { startDate, hireDate } = this.state;
    this.props.updateDates(this.props.email, toISODateString(startDate), toISODateString(hireDate));
    this.props.onHide();
  };

  updateStartDate = (dateString, startDate) => {
    this.setState({ startDate });
  }

  updateHireDate = (dateString, hireDate) => {
    this.setState({ hireDate });
  }

  render() {
    const { visible, onHide } = this.props;

    const { startDate, hireDate } = this.state;

    return (
      <DialogContainer
        id="edit-dates-dialog"
        aria-labelledby="edit-dates-dialog-title"
        visible={visible}
        onHide={onHide}
        fullPage
      >
        
        <form
          component="form"
          onSubmit={this.handleSubmit}
        >
   
          <Toolbar
            nav={<Button icon onClick={onHide}>arrow_back</Button>}
            title="Editar datas (férias)"
            titleId="edit-dates-dialog-title"
            fixed
            colored
            actions={<Button type="submit" flat>Enviar</Button>}
          />
            <section className="md-grid" aria-labelledby="dates-title">
            <h2 id="contract-title" className="md-cell md-cell--12">
              "Datas (férias)"
            </h2>

            <DatePicker
              id="start-date"
              label="Data de ingresso"
              className="md-cell md-cell--6"
              
              value={startDate}
              onChange={this.updateStartDate}
              required
              autoOk
            />

            <DatePicker
              id="start-date"
              label="Data de férias"
              className="md-cell md-cell--6"
              
              value={hireDate}
              onChange={this.updateHireDate}
              required
              autoOk
            />
          </section>    

        </form>
      </DialogContainer>
    );
  }
}