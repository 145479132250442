import PropTypes from 'prop-types';
import React from 'react';
import { FontIcon, ListItem } from 'react-md';
import { Link as ReactLink, Route } from 'react-router-dom';

/**
 * Workaround for react-router using context, while most of the components
 * in react-md use PureComponent, which blocks the context updates.
 * Wraps the component around with Route to make sure that the active state is 
 * correctly applied after an item has been clicked.
 */
export const NavItem = ({ label, to, icon, exact }) => (
  <Route path={to} exact={exact}>
    {({ match }) => {
      let leftIcon;
      if (icon) {
        leftIcon = <FontIcon>{icon}</FontIcon>;
      }
      return (
        <ListItem
          component={Link}
          active={!!match}
          to={to}
          primaryText={label}
          leftIcon={leftIcon}
        />
      );
    }}
  </Route>
);

class Link extends React.Component {
  parseTo(to) {
    let parser = document.createElement('a');
    parser.href = to;
    return parser;
  }
  isInternal(toLocation) {
    return window.location.host === toLocation.host;
  }

  render() {
    const {to, children, ...rest} = this.props;
    const toLocation = this.parseTo(to);
    const isInternal = this.isInternal(toLocation);
    if (isInternal) {
      return (<ReactLink to={toLocation.pathname} {...rest}>{children}</ReactLink>);
    } else {
      return (<a href={to} target="_blank" rel="noopener noreferrer" {...rest}>{children}</a>);
    }
  }
}

NavItem.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string,
  exact: PropTypes.bool,
  icon: PropTypes.node,
};