export const LANDING = '/';
export const BH_OVERVIEW = '/bh';
export const BH_DETAILS = '/bh/details';

export const VACATIONS_OVERVIEW = '/vacations';

export const BH_DASHBOARD = '/dashboard/bh';
export const VACATIONS_DASHBOARD = '/dashboard/vacations';

export const BH_USER_OVERVIEW = '/bh/:email';
export const BH_USER_DETAILS = '/bh/details/:email';
export const VACATIONS_USER_OVERVIEW = '/vacations/:email';

export const USER_ADD = '/user/add';
export const USER_EDIT = '/user/edit/:email';
export const USER_EDIT_ROOT = '/user/edit/';

export const CONTRACT_USER = '/contract/:email'
export const CONTRACT_USER_EDIT = '/contract/edit/:email';
export const CONTRACT_USER_EDIT_ROOT = '/contract/edit/';


export const SIGN_IN = '/sign-in';
export const FORBIDDEN = '/403';

export const ABOUT = '/about';;

export const LICENSE_FORM = 'https://docs.google.com/a/kvarch.com/forms/d/e/1FAIpQLSfFjOcOPjm27y8pLt0IUd6nxiHSBuCy4SRb6RtKfIeNa8gQMg/viewform';