import React, { Component } from 'react';
import { Cell, Grid, Snackbar } from 'react-md';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { TabLayout } from '../components';
import { getVacationsAllThunk, deleteUser } from '../actions';

import { DataTable } from '../data-table';
import { VacationsCalendar, DeleteDialog } from '../components';

import * as routes from '../routes';
import { replaceCommasWithDots, nextVacationDate } from '../utils';



const COLS = [
  {
    key: "displayName",
    label: "Nome",
    type: "text"
  },
  {
    key: "available",
    label: "Disponível",
    type: "numeric",
    class: "md-font-semibold"
  },
  {
    key: "earned",
    label: "Adquiridas",
    type: "numeric"
  },
  {
    key: "spent",
    label: "Gastas",
    type: "numeric"
  },
  {
    key: "scheduled",
    label: "Agendadas (próx. 12 meses)",
    type: "numeric"
  },
  {
    key: "extraDays",
    label: "Ajuste",
    type: "numeric"
  },
  { 
    key: "next",
    label: "Mês do próximo acréscimo (+30d)",
    type: "text"
  },
  { 
    key: "startDate",
    label: "Data de ingresso",
    type: "text"
  },
  {
    key: "details",
    label: "Detalhes",
    type: "url_button"
  },
  {
    key: "edit",
    label: "Editar",
    type: "url_button"
  },
  {
    key: "delete",
    label: "Remover",
    type: "action_button"
  }
]


class VacationsDashboard extends Component {

  state = { 
    dialogVisible: false,
    userToDelete: null,
    deleteMessage: '',
    toasts: [] 
  }

  parseVacationEntry = (key, entry) => {
    return {
      key: key,
      displayName: entry.displayName,
      available: entry.available,
      earned: entry.earned,
      spent: entry.spent,
      scheduled: entry.scheduled,
      extraDays: entry.extraDays,
      bu: entry.bu,
      startDate: entry.startDate,
      next: nextVacationDate(entry.hireDate),
      details: {
        url: routes.VACATIONS_OVERVIEW + '/' + encodeURIComponent(replaceCommasWithDots(key)),
        icon: "search"
      },
      edit: {
        url: routes.USER_EDIT_ROOT + encodeURIComponent(replaceCommasWithDots(key)),
        icon: "edit"
      },
      delete: {
        action:  () => this.showDeleteDialog(replaceCommasWithDots(key)),
        icon: "delete"
      }
    }
  }


  showDeleteDialog = (email) => {

    this.setState({
      deleteMessage: `O usuário ${email}`,
      userToDelete: email
    });
    this.setState({ dialogVisible: true });
  };

  hideDeleteDialog = () => {
    this.setState({ 
      userToDelete: null,
      dialogVisible: false
    });
  };

  deleteUserAction = () => {

    deleteUser(this.state.userToDelete)
      .then(() => {
      if (!this.state.toasts.length) {
        const toasts = this.state.toasts.slice();
        toasts.push({
          text: 'Usuário removido',
          action: 'Ok',
        })
        this.setState({ toasts, userToDelete: null, deleteMesage: '' });
      }
    })
    .catch(err => {

      const errString = JSON.stringify(err);

      if (!this.state.toasts.length) {
        const toasts = this.state.toasts.slice();
        toasts.push({
          text: `Erro ao removerUsuário: ${errString}`,
          action: 'Ok',
        });
        this.setState({ toasts });
      }
    });
    
  }

  handleDismiss = () => {
    const [, ...toasts] = this.state.toasts;
    this.setState({ toasts });
  };

  componentDidMount() {
    document.title = "Administração - Férias";
  }

  render() {
    const { dialogVisible, toasts, deleteMessage } = this.state;
    const { vacations } = this.props;
    const rows = Object.keys(vacations).map(key => this.parseVacationEntry(key, vacations[key]));
    const events = Object.keys(vacations).map(key => ({ user: vacations[key].displayName, events: vacations[key].events})) || [];

    const tabs = [
      {
        label: "Tabela",
        icon: 'grid_on',
        component: (
          <Grid>
            <Cell size={12}>
              <DataTable dataColumns={COLS} dataRows={rows} title="Férias dos usuários" />
              <DeleteDialog
                onConfirm={() => this.deleteUserAction()}
                onHide={this.hideDeleteDialog}
                visible={dialogVisible}
                name={deleteMessage}
              />
              <Snackbar id="contract-toasts" toasts={toasts} onDismiss={this.handleDismiss} />   
            </Cell>
          </Grid>
          )
      },
      {
        label: "Agenda",
        icon: 'event',
        component: (
          <Grid>
            <Cell size={12} style={{height: "800px" }}>
              { events && <VacationsCalendar data={events} /> }
            </Cell>
          </Grid>
        )
      }
    ]
    return <TabLayout tabs={tabs} />
  }
}

const mapStateToProps = (state) => ({
  vacations: state.allVacationsState.allVacations,
});

const mapDispatchToProps = (dispatch) => {
  dispatch(getVacationsAllThunk());
  return {}
}


export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(VacationsDashboard);
