import React, { PureComponent } from 'react';
import moment from 'moment';
import { Button, TableRow, EditDialogColumn, TableColumn } from 'react-md';

import { updateContractDate, updateContractHoursPerDay, updateContractMultiplier } from '../../actions';


const parseAsNumber = (value) => {
  const parsed = typeof value === 'string' ?
    parseFloat(value.replace(/,/g, '.')) : value;
  return isNaN(parsed) ? null : parsed;
}

export class ContractRow extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      date: props.date,
      multiplier: props.multiplier,
      hoursPerDay: props.hoursPerDay
    }
  }


  updateHoursPerDay = (newValue) => {
    const parsed = parseAsNumber(newValue);
    return parsed && parsed !== this.props.hoursPerDay ?
    updateContractHoursPerDay(
      this.props.email, 
      this.state.date, 
      parseFloat(newValue.replace(/,/g, '.')) // Handles eventual comma as separator
    )
    .then(() => this.props.onEdit(`Contrato alterado com sucesso`))
    : null;
  }


  updateMultiplier = (newValue) => {

    const parsed = parseAsNumber(newValue);
    return parsed && parsed !== this.props.multiplier ?
    updateContractMultiplier(
      this.props.email,
      this.state.date,
      parseFloat(newValue.replace(/,/g, '.')) // Handles eventual comma as separator
    )
    .then(() => this.props.onEdit(`Contrato alterado com sucesso`))
    : null;
  }

  updateDate = (newDate) =>
    moment(newDate, "YYYY-MM-DD", true).isValid() && newDate !== this.props.date ? 
      updateContractDate (this.props.email, this.props.date, newDate)
        .then(() => this.props.onEdit(`Contrato alterado com sucesso`))
        .catch((err) => {
          this.setState({ date: this.props.date }); // Reverse change
          this.props.onEdit(err)
        })
     : null


  changeDate = (date) =>
    this.setState({ date });

  changeMultiplier = (multiplier) =>
    this.setState({ multiplier });

  changeHoursPerDay = (hoursPerDay) =>
    this.setState({ hoursPerDay });


  render() {
    const {
      date,
      hoursPerDay,
      multiplier
    } = this.state;

    const {
      onDelete
    } = this.props;
    
    return (
      <TableRow>
        <EditDialogColumn 
          key="date"
          value={date}
          onChange={this.changeDate}
          onOkClick={this.updateDate}
        />
        <EditDialogColumn
          key="hours-per-day"
          value={hoursPerDay}
          onChange={this.changeHoursPerDay}
          onOkClick={this.updateHoursPerDay}
        />
        <EditDialogColumn
          key="multiplier"
          value={multiplier}
          onChange={this.changeMultiplier}
          onOkClick={this.updateMultiplier}
        />
        <TableColumn key="delete">
          <Button onClick={onDelete} icon>delete</Button>
        </TableColumn>
      </TableRow>
    );
  }
}