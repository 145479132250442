import { FETCH_BH_USER } from '../../actions/types';

const INITIAL_STATE = {
  userBH: {},
};

const applySetBH = (state, action) => ({
  ...state,
  userBH: action.bh
});

function userBHReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case FETCH_BH_USER: {
      return applySetBH(state, action);
    }
    default : return state;
  }
}

export default userBHReducer;