import React from 'react';
import { ExpansionPanel, ExpansionList, FontIcon, IconSeparator, List, ListItem } from 'react-md';

import { nextVacationDate } from '../utils';


const itemValue = (icon, value) => 
  <IconSeparator label={value} iconBefore>
    <FontIcon>{icon}</FontIcon>
  </IconSeparator>

export const VacationsOverview = (props) =>
  <React.Fragment>
    <List>
      { props.hasOwnProperty('available') && <ListItem primaryText="Dias disponíveis (não agendados)" rightIcon={itemValue("event_note", props.available)} /> }
      { props.hasOwnProperty('scheduled') && <ListItem primaryText="Dias agendados (próximos 12 meses)" rightIcon={itemValue("event", props.scheduled)} /> }
      { props.hasOwnProperty('hireDate') && <ListItem primaryText="Mês do próximo acréscimo (+30d)" rightIcon={itemValue("update", nextVacationDate(props.hireDate))} /> }
    </List>
    <ExpansionList>
      <ExpansionPanel label="Histórico"  footer={null}>
        <List>
          { props.hasOwnProperty('earned') && <ListItem primaryText="Total de dias adquiridos" rightIcon={itemValue("event_available", props.earned)} /> }
          { props.hasOwnProperty('spent') && <ListItem primaryText="Total de dias gastos" rightIcon={itemValue("event_busy", props.spent)} /> }
          { props.hasOwnProperty('extraDays') && <ListItem primaryText="Ajuste" rightIcon={itemValue("cake", props.extraDays)} /> }
        </List>
      </ExpansionPanel>
    </ExpansionList>
  </React.Fragment>
  