import React, { PureComponent } from 'react';
import { TextField, SelectField, SelectionControl, DatePicker, Slider, Card, CardTitle, CardText, CardActions, Button, FontIcon, Snackbar } from 'react-md';

import { createUser } from '../../actions';

import { isEmpty, isInvalidEmail, toISODateString } from '../../utils';

import { HelpBox } from './help';

import { buEnum } from '../../enum';


const selectItems = Object.keys(buEnum).map(key =>
    ({
      label: buEnum[key],
      value: key.toString()
    }))

const Indicator = ({ children }) => <span className="md-slider-ind md-text">{children}</span>;
const TextIndicator = ({ children }) => <span className="md-text" style={{marginBottom: "0.8rem"}}>{children}</span>;

const INITIAL_STATE = {
  emailBlur: false,
  nameBlur: false,
  helpVisible: false,
  toasts: [],
  emailError: false,
  nameError: false,
  email: '',
  displayName: '',
  bu: '2',
  isAdmin: false,
  hoursPerDay: 8.8,
  multiplier: 1.5,
  hireDate: new Date(),
  startDate: new Date()
};

export class UserAdd extends PureComponent {

  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleIsAdminChange = this.handleIsAdminChange.bind(this);
    this.handleBuChange = this.handleBuChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    document.title = "Criar usuário"
  }


  handleSubmit = (e) => {
    e.preventDefault();
    
    const {
      email,
      displayName,
      startDate,
      hireDate,
      hoursPerDay,
      multiplier,
      bu,
      isAdmin 
    }  = this.state;

    createUser(
      `${email}@kvarch.com`,
      displayName,
      toISODateString(startDate),
      toISODateString(hireDate),
      hoursPerDay,
      multiplier,
      parseInt(bu, 10),
      isAdmin
    )
    .then(() => {
      if (!this.state.toasts.length) {
        const toasts = this.state.toasts.slice();
        toasts.push({
          text: 'Usuário criado',
          action: 'Ok',
        });

        this.setState(INITIAL_STATE);  
        this.setState({ toasts });
      }
    })
    .catch(err => {

      const errString = JSON.stringify(err);

      if (!this.state.toasts.length) {
        const toasts = this.state.toasts.slice();
        toasts.push({
          text: `Erro ao criar usuário: ${errString}`,
          action: 'Ok',
        });
        this.setState({ toasts });
      }
    })
  };

  handleDismiss = () => {
    const [, ...toasts] = this.state.toasts;
    this.setState({ toasts });
  };

  updateHoursPerDay = (hoursPerDay) => {
    this.setState({ hoursPerDay });
  };

  updateMultiplier = (multiplier) => {
    this.setState({ multiplier });
  };

  updateHireDate = (dateString, hireDate) => {
    this.setState({ hireDate });
  }

  updateStartDate = (dateString, startDate) => {
    this.setState({ startDate });
  }

  handleNameChange(value, event) {
    this.setState({ displayName: value});
    this.setState({ nameError: isEmpty(value) });
  }


  handleEmailChange(value, event) {
    this.setState({ email: value});
    this.setState({ emailError: isInvalidEmail(value) });
  }

  handleIsAdminChange(value, event) {
    this.setState({ isAdmin: value});
    this.setState({ nameError: isEmpty(value)  });
  }

  handleBuChange(bu, event) {
    this.setState({ bu });
  }

  toggleHelp = () => {
    this.setState({ helpVisible: !this.state.helpVisible });
  };

  handleNameBlur = () => {
    this.setState({ nameBlur: true })
  }

  handleEmailBlur = () => {
    this.setState({ emailBlur: true })
  }

  setTemplateIntern = () => {
    this.setState({
      hoursPerDay: 6,
      multiplier: 1
    })
  }

  setTemplatePartTime = () => {
    this.setState({
      hoursPerDay: 6,
      multiplier: 1.5
    })
  }

  setTemplateFullTime = () => {
    this.setState({
      hoursPerDay: 8.8,
      multiplier: 1.5
    })
  }


  render() {

    const {
      toasts,
      emailError,
      emailBlur,
      nameError,
      nameBlur,
      helpVisible,
      email,
      displayName,
      isAdmin,
      bu,
      hoursPerDay,
      multiplier,
      startDate,
      hireDate    
    } = this.state;

    const isDisabled = nameError || emailError || !emailBlur || !nameBlur;

    return (
      <Card>
        <CardTitle title="Criar novo usuário">
          <Button className="md-cell--right" onClick={this.toggleHelp} icon>help_outline</Button>
        </CardTitle>
        
      <form className="text-fields__application" onSubmit={this.handleSubmit}>
        <CardText>
          <HelpBox collapsed={!helpVisible} />

          <h4 className="md-text-uppercase">Usuário</h4>

          <TextField
            id="email"
            label="Email"
            leftIcon={<FontIcon>email</FontIcon>}
            type="text"
            className="md-cell md-cell--6"
            value={email}
            onChange={this.handleEmailChange}
            rightIcon={<TextIndicator>@kvarch.com</TextIndicator>}
            error={emailError}
            required
            onBlur={this.handleEmailBlur}
            helpOnFocus
            helpText="Não incluir o @kvarch.com"
          />
          <TextField
            id="name"
            label="Nome"
            leftIcon={<FontIcon>person</FontIcon>}
            className="md-cell md-cell--6"
            required
            value={displayName}
            onChange={this.handleNameChange}
            onBlur={this.handleNameBlur}
            error={nameError}
          />

          <SelectField
            id="bu"
            label="Gerência"
            className="md-cell md-cell--6"
            menuItems={selectItems}
            defaultValue={bu}
            value={bu}
            onChange={this.handleBuChange}
            position={SelectField.Positions.BELOW}
            required
          /> 

          <SelectionControl
            id="is-admin"
            type="checkbox"
            label="É administrador do banco de horas?"
            name="admin"
            inline
            className="md-cell md-cell--12"
            checked={isAdmin}
            onChange={this.handleIsAdminChange}
          />

          <DatePicker
            id="start-date"
            label="Data de férias"
            className="md-cell md-cell--6"
            
            value={hireDate}
            onChange={this.updateHireDate}
            required
            autoOk
          />

          <br />
          <h4 className="md-text-uppercase md-text-field--floating-margin">Dados do contrato (BH)</h4>

          <DatePicker
            id="start-date"
            label="Data de ingresso"
            className="md-cell md-cell--6"
            
            value={startDate}
            onChange={this.updateStartDate}
            required
            autoOk
          />

          <div>
            <h5>Templates (clique para ativar)</h5>
            <Button
              flat
              primary
              iconChildren="directions_run"
              onClick={this.setTemplateFullTime}
            >
              Tempo integral
            </Button>
            <Button
              flat
              primary
              iconChildren="directions_walk"
              onClick={this.setTemplatePartTime}
            >
              Tempo parcial
            </Button>
            <Button
              flat
              primary
              iconChildren="child_friendly"
              onClick={this.setTemplateIntern}
            >
              Estagiário
            </Button>
          </div>


          <Slider
            id="hours-per-day"
            min={0}
            step={0.2}
            max={24}
            editable
            value={hoursPerDay}
            onChange={this.updateHoursPerDay}          
            leftIcon={<Indicator>Horas por dia</Indicator>}
            className="md-cell md-cell--6"
          />

          <Slider
            id="multiplier"
            min={0}
            max={3}
            step={0.1}
            editable
            value={multiplier}
            onChange={this.updateMultiplier}
            leftIcon={<Indicator>Multiplicador</Indicator>}
            className="md-cell md-cell--6"
          />
          </CardText>

          <CardActions className="md-cell md-cell--12">
            <Button
            flat
            primary
            type="submit"
            disabled={isDisabled}
            >Enviar</Button>
          </CardActions>

          <Snackbar id="user-toasts" toasts={toasts} onDismiss={this.handleDismiss} />   

        </form>
      </Card>
    )
  }
}