import moment from 'moment';
import React, { PureComponent } from 'react';
import { Avatar, DialogContainer, FontIcon, List, ListItem } from 'react-md';

const safeDateString = (date) => 
  !date? "" : (date instanceof moment)? date.format('L') : "";


export class EventDialog extends PureComponent {

  hide = () => {
    this.props.onCloseDialog(true);
  };

  handleKeyDown = (e) => {
    const key = e.which || e.keyCode;
    if (key === 13 || key === 32) {
      // also close on enter or space keys
      this.hide();
    }
  };

  render() {

    return (
      <DialogContainer
        width="50%"
        id="event-dialog"
        visible={this.props.visible}
        title={this.props.title? this.props.title : "Férias"}
        onHide={this.hide}
      >
        <List onClick={this.hide} onKeyDown={this.handleKeyDown}>
          <ListItem
            leftAvatar={<Avatar suffix="green" icon={<FontIcon>flight_takeoff</FontIcon>} />}
            primaryText="Início"
            secondaryText={safeDateString(this.props.start)}
          />
          <ListItem
            leftAvatar={<Avatar suffix="red" icon={<FontIcon>flight_land</FontIcon>} />}
            primaryText="Término"
            secondaryText={safeDateString(this.props.end)}
          />
        </List>
      </DialogContainer>
    )
  }
}