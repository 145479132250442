import React from 'react';
import { Button, CardActions } from 'react-md';
import { withRouter } from 'react-router-dom';

import * as routes from '../../../routes';

import { ExpandableCard } from './expandable-card';
;


const BaseCurrentPeriodCard = (props) => {
  const url = props.email ? routes.BH_DETAILS + '/' + encodeURIComponent(props.email) : routes.BH_DETAILS;
 
  return(
    <React.Fragment>
      <BankedHoursCard {...props} />
      <BalanceCard onClick={() => props.history.push(url)} {...props} />
    </React.Fragment>
  )
 }

 export const CurrentPeriodCard = withRouter(BaseCurrentPeriodCard)


const BankedHoursCard = (props) => {
  const title = {
    label: "Disponível no banco de horas",
    icon: "weekend",
    value: props.previousPeriod + props.nwdAccrued
  }

  const content = [
    {
      label: "Fechamento período anterior",
      icon: "add",
      value: props.previousPeriod
    },
    {
      label: "Adicionadas FDS e feriados",
      icon: "add",
      value: props.nwdAccrued
    },
    {
      label: "dividier",
      divider: true
    },
    {
      label: "Disponível",
      icon: "functions",
      value: props.previousPeriod + props.nwdAccrued
    },
  ]
  return (
    <ExpandableCard title={title} body={content} />
  )
}


 const BalanceCard = (props) => {


  const title = {
    label: "Saldo do período atual",
    icon: "iso",
    value: props.balance
  }

  const content = [
    {
      label: "Horas trabalhadas (dia útil)",
      icon: "add",
      value: props.worked - props.nwdWorked
    },
    {
      label: "Horas de licença",
      icon: "add",
      value: props.license
    },
    {
      label: "Expediente",
      icon: "remove",
      value: props.reference
    },
    {
      label: "dividier",
      divider: true
    },
    {
      label: "Saldo",
      icon: "functions",
      value: props.balance
    },
  ]
  return (
    <ExpandableCard title={title} body={content} >
      {/*
      <CardActions expandable>
        <Button flat primary onClick={props.onClick}>Ver detalhes</Button>
      </CardActions>
      */}
    </ExpandableCard>
  )
}
