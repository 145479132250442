import React from 'react';
import { VerticalTimeline } from 'react-vertical-timeline-component';
import { ContractElement } from "./contract-element";

export const ContractsTimeline = (props) => {

  const elements = Object.keys(props.contracts).map(date => 
    (<ContractElement
      key={date}
      date={date}
      hoursPerDay={props.contracts[date].hoursPerDay}
      multiplier={props.contracts[date].multiplier}
    />)
  );

  return (<VerticalTimeline>
    {elements}      
  </VerticalTimeline>)
  
}