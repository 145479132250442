import { db } from '../lib/firebase';
import { replaceDotsWithCommas } from '../utils';


const BH_PREFIX = 'bh_v2';
const DAILY_PREFIX = 'daily';
const VACATIONS_PREFIX = 'vacations_v2';
const CONTRACTS_PREFIX = 'contracts';
const USERS_PREFIX = 'users_v2';


// User API
export const createUser = (email, displayName, startDate, hireDate, hoursPerDay, multiplier, bu, isAdmin) => {

  const key = replaceDotsWithCommas(email);

  return  db.ref(`${BH_PREFIX}/${key}`).set(initBHProps(displayName, bu))
    .then(() => db.ref(`${VACATIONS_PREFIX}/${key}`).set(initVacationProps(displayName, bu, startDate, hireDate)))
    .then(() => db.ref(`${CONTRACTS_PREFIX}/${key}`).set(initContractProps(startDate, hoursPerDay, multiplier)))
    .then(() => db.ref(`${USERS_PREFIX}/${key}`).set(initUserProps(displayName, bu, isAdmin)))
}

  
export const onceGetUser = (email) => 
  Promise.resolve(replaceDotsWithCommas(email))
    .then(key => db.ref(`${USERS_PREFIX}/${key}`).once('value'))

export const updateUser = (email, displayName, bu, role, extraDays) => {
  const key = replaceDotsWithCommas(email);
  return db.ref(`${USERS_PREFIX}/${key}`).update({ displayName, bu, role })
    .then(() => db.ref(`${BH_PREFIX}/${key}`).update({ displayName, bu }))
    .then(() => db.ref(`${VACATIONS_PREFIX}/${key}`).update({ displayName, bu, extraDays}))
}

export const deleteUser = (email) => {
  const key = replaceDotsWithCommas(email);
  if(key === '' || !key) {
    return Promise.reject("Invalid user specified");
  }
  
  return db.ref(`${USERS_PREFIX}/${key}`).remove()
    .then(() => db.ref(`${BH_PREFIX}/${key}`).remove())
    .then(() => db.ref(`${VACATIONS_PREFIX}/${key}`).remove())
    .then(() => db.ref(`${CONTRACTS_PREFIX}/${key}`).remove())
    .then(() => db.ref(`${DAILY_PREFIX}/${key}`).remove())
}

export const onceIsAdmin = (email) => 
  Promise.resolve(replaceDotsWithCommas(email))
    .then(key => db.ref(`${USERS_PREFIX}/${key}`).once('value'))
    .then(snapshot => snapshot.exists() && (snapshot.val().role === 'admin'));
  


const initBHProps = (displayName, bu) => 
  ({
    displayName,
    bu,
    nwdWorked: 0,
    worked: 0,
    balance: 0,
    reference: 0,
    license: 0,
    nwdAccrued: 0,
    toAccrue: 0,
    previousPeriod: 0,
    history: null
  });

const initVacationProps = (displayName, bu, startDate, hireDate) =>
  ({
    displayName,
    bu,
    earned: 0,
    spent: 0,
    scheduled: 0,
    available: 0,
    startDate,
    hireDate,
    extraDays: 0,
    events: []
  });

const initUserProps = (displayName, bu, isAdmin) => 
  isAdmin ? { displayName, bu, role: 'admin' } : { displayName, bu, role: 'user' };
  
  
const initContractProps = (startDate, hoursPerDay, multiplier) => {
  const output = {}
  output[startDate] = {
    hoursPerDay,
    multiplier
  }
  return output;
}


// CONTRACT APIS

export const addUserContract = (email, date, hoursPerDay, multiplier) =>
  Promise.resolve(replaceDotsWithCommas(email))
    .then(key => db.ref(`${CONTRACTS_PREFIX}/${key}/${date}`).set({ hoursPerDay, multiplier}))

export const deleteUserContract = (email, date) =>
  Promise.resolve(replaceDotsWithCommas(email))
    .then(key => db.ref(`${CONTRACTS_PREFIX}/${key}/${date}`).remove())

export const updateContractMultiplier = (email, date, multiplier) =>
  Promise.resolve(replaceDotsWithCommas(email))
    .then(key => db.ref(`${CONTRACTS_PREFIX}/${key}/${date}`).update({ multiplier }));

export const updateContractHoursPerDay = (email, date, hoursPerDay) =>
    Promise.resolve(replaceDotsWithCommas(email))
      .then(key => db.ref(`${CONTRACTS_PREFIX}/${key}/${date}`).update({ hoursPerDay }));

export const updateContractDate = (email, previousDate, newDate) => {

  let encodedEmail;
  return Promise.resolve(replaceDotsWithCommas(email))
    .then(key => {
      encodedEmail = key;
      return db.ref(`${CONTRACTS_PREFIX}/${key}/${newDate}`).once('value');
    })
    .then(currentSnapshot => 
      currentSnapshot.exists() ? 
        Promise.reject("Já existe um contrato na data especificada") :
        db.ref(`${CONTRACTS_PREFIX}/${encodedEmail}/${previousDate}`).once('value')
    )
    .then(snapshot =>  db.ref(`${CONTRACTS_PREFIX}/${encodedEmail}/${newDate}`).set( snapshot.val() ))
    .then(() => db.ref(`${CONTRACTS_PREFIX}/${encodedEmail}/${previousDate}`).remove());     

}


// VACATIONS API

export const onceGetUserVac = (email) => 
    Promise.resolve(replaceDotsWithCommas(email))
      .then(key => db.ref(`${VACATIONS_PREFIX}/${key}`).once('value'));

export const updateVacDates = (email, startDate, hireDate) =>
  Promise.resolve(replaceDotsWithCommas(email))
    .then(key => db.ref(`${VACATIONS_PREFIX}/${key}`).update({ startDate, hireDate }));



