import { FETCH_VAC_ALL } from '../../actions';

const INITIAL_STATE = {
  allVacations: {},
};

const applySetVacations = (state, action) => ({
  ...state,
  allVacations: action.vacations
});

function allVacationsReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case FETCH_VAC_ALL: {
      return applySetVacations(state, action);
    }
    default : return state;
  }
}

export default allVacationsReducer;