import React, { Component } from 'react';
import { Cell, Grid, Snackbar } from 'react-md';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getBHAllThunk, deleteUser } from '../actions';
import { SummaryLayout } from './summary';
import { TabLayout, DeleteDialog } from '../components';
import { DataTable } from '../data-table';

import * as routes from '../routes';
import { replaceCommasWithDots } from '../utils';


const COLS = [
  {
    key: "displayName",
    label: "Nome",
    type: "text"
  },
  {
    key: "available",
    label: "Disponível (BH)",
    type: "numeric",
    class: "md-font-semibold",
    conditional: (value) => value >= 0
  },
  {
    key: "worked",
    label: "A - Toggl (total)",
    type: "numeric"
  },
  {
    key: "nwdWorked",
    label: "B - Toggl (FDS/Fer.)",
    type: "numeric"
  },
  {
    key: "license",
    label: "C - Licenças",
    type: "numeric"
  },
  {
    key: "reference",
    label: "D - Expediente",
    type: "numeric"
  },
  {
    key: "balance",
    label: "Saldo período (A - B + C - D)",
    type: "numeric",
    class: "md-font-semibold",
    conditional: (value) => value >= 0
  },
  {
    key: "details",
    label: "Detalhes",
    type: "url_button"
  },
  {
    key: "edit",
    label: "Editar",
    type: "url_button"
  },
  {
    key: "delete",
    label: "Remover",
    type: "action_button"
  }
]


const good = "#1EB980";
const bad = "#FF6859";


class BankedHoursDashboard extends Component {

  state = { 
    dialogVisible: false,
    userToDelete: null,
    deleteMessage: '',
    toasts: [] 
  }


  parseBHEntry = (key, entry) => {
    console.log(`${key}: ${entry.previousPeriod} : ${entry.nwdAccrued}`)
    return {
      key: key,
      displayName: entry.displayName,
      available: (entry.previousPeriod + entry.nwdAccrued),
      worked: entry.worked,
      nwdWorked: entry.nwdWorked,
      license: entry.license,
      reference: entry.reference,
      balance: entry.balance,
      bu: entry.bu,
      details: {
        url: routes.BH_OVERVIEW + '/' + encodeURIComponent(replaceCommasWithDots(key)),
        icon: "search"
      },
      edit: {
        url: routes.USER_EDIT_ROOT + encodeURIComponent(replaceCommasWithDots(key)),
        icon: "edit"
      },
      delete: {
        action:  () => this.showDeleteDialog(replaceCommasWithDots(key)),
        icon: "delete"
      }
    }
  }

  showDeleteDialog = (email) => {

    this.setState({
      deleteMessage: `O usuário ${email}`,
      userToDelete: email
    });
    this.setState({ dialogVisible: true });
  };

  hideDeleteDialog = () => {
    this.setState({ 
      userToDelete: null,
      dialogVisible: false
    });
  };

  deleteUserAction = () => {

    deleteUser(this.state.userToDelete)
      .then(() => {
      if (!this.state.toasts.length) {
        const toasts = this.state.toasts.slice();
        toasts.push({
          text: 'Usuário removido',
          action: 'Ok',
        })
        this.setState({ toasts, userToDelete: null, deleteMesage: '' });
      }
    })
    .catch(err => {

      const errString = JSON.stringify(err);

      if (!this.state.toasts.length) {
        const toasts = this.state.toasts.slice();
        toasts.push({
          text: `Erro ao remover Usuário: ${errString}`,
          action: 'Ok',
        });
        this.setState({ toasts });
      }
    });
    
  }

  handleDismiss = () => {
    const [, ...toasts] = this.state.toasts;
    this.setState({ toasts });
  };

  componentDidMount() {
    document.title = "Administração - Banco de Horas"
  }

  render() {
    const { dialogVisible, toasts, deleteMessage } = this.state;
    const { bh } = this.props;
    const rows = Object.keys(bh).map(key => this.parseBHEntry(key, bh[key]));

    const content = [
      {
        title:"5 maiores bancos de horas",
        color: good,
        dataProp: "available",
        categoryProp: "displayName",
        ascending: false,
        quantity: 5
      },
      {
        title:"5 menores bancos de horas",
        color: bad,
        dataProp: "available",
        categoryProp: "displayName",
        ascending: true,
        quantity: 5
      },
      {
        title:"5 maiores saldos do período atual",
        color: good,
        dataProp: "balance",
        categoryProp: "displayName",
        ascending: false,
        quantity: 5
      },
      {
        title:"5 menores saldos do período atual",
        color: bad,
        dataProp: "balance",
        categoryProp: "displayName",
        ascending: true,
        quantity: 5
      }
    ]

    const tabs = [
      
      {
        label: "Tabela",
        icon: 'grid_on',
        component: (
          <Grid>
            <Cell size={12}>
              <DataTable dataColumns={COLS} dataRows={rows} title="Banco de horas dos usuários" />
              
              <Snackbar id="contract-toasts" toasts={toasts} onDismiss={this.handleDismiss} />
            </Cell>
          </Grid>
          )
      },
      {
        label: "Resumo",
        icon: 'event',
        component: (
          <Grid>
            <Cell size={12} style={{height: "800px" }}>
              <SummaryLayout data={rows} content={content} />
            </Cell>
          </Grid>
        )
      }     
    ]

    return <React.Fragment>
      <TabLayout tabs={tabs} />
      <DeleteDialog
        onConfirm={() => this.deleteUserAction()}
        onHide={this.hideDeleteDialog}
        visible={dialogVisible}
        name={deleteMessage}
      />
    </React.Fragment>
  }
}


const mapStateToProps = (state) => ({
  bh: state.allBHState.allBH,
});


const mapDispatchToProps = (dispatch) => {
  dispatch(getBHAllThunk());
  return {}
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(BankedHoursDashboard);