import React, { Component } from 'react';
import { Cell, Grid } from 'react-md';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getVacationsUserThunk } from '../actions';
import { TabLayout, VacationsCalendar } from '../components';
import { VacationsOverview } from './overview';
import { componentEmail } from '../utils';

class UserVacations extends Component {

  componentDidMount() {
    const email = componentEmail(this.props);
    document.title = `Férias - ${email}`;
  }

  render() {
    const { vacations } = this.props;

    const tabs = [
      {
        label: "Resumo",
        icon: 'work_off',
        component: (
          <Grid>
            <Cell size={12}>
              <VacationsOverview
                scheduled={vacations.scheduled}
                available={vacations.available}
                hireDate={vacations.hireDate}
                earned={vacations.earned}
                spent={vacations.spent}
                extraDays={vacations.extraDays}
              />
            </Cell>
          </Grid>
        )
      },
      {
        label: "Agenda",
        icon: 'event',
        component: (
          <Grid>
           <Cell size={12} style={{height: "800px" }}>
          { !!vacations && !!vacations.events  && <VacationsCalendar data={[{ user: vacations.displayName, events: vacations.events}]} /> }
        </Cell>
          </Grid>
          )
      }      
    ]

    return <TabLayout tabs={tabs} />

  }
}


const mapStateToProps = (state) => ({
  vacations: state.userVacationsState.userVacations,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const email = componentEmail(ownProps);
  dispatch(getVacationsUserThunk(email))
  return {
  }
 }

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(UserVacations);