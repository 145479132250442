import { FETCH_DAILY_USER } from '../../actions';

const INITIAL_STATE = {
  userDaily: {},
};

const applySetDaily = (state, action) => ({
  ...state,
  userDaily: action.daily
});

function userDailyReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case FETCH_DAILY_USER: {
      return applySetDaily(state, action);
    }
    default : return state;
  }
}

export default userDailyReducer;