import moment from 'moment';

export const componentEmail = (props) =>
  props.match.params && props.match.params.email ? decodeURIComponent(props.match.params.email) : props.authUser.email;


export const nextVacationDate = (date) => {
  const today = moment();
  const parsed = moment(date);
  // Zero indexed, omitting the day uses the first day of the month
  const reference = moment([today.year(), parsed.month()]);
  return reference < today ? reference.add(1, "years").format('MM/YYYY') : reference.format('MM/YYYY');
}
  