import React from 'react';
import PropTypes from 'prop-types';
import { TableCardHeader } from 'react-md';

import { FlatOrIconButton } from './flat-or-icon-button';

export const TableActions = ({ onAddClick }) => (
  <TableCardHeader title="Contratos" visible={false}>
    <FlatOrIconButton onClick={onAddClick} mobile={false} iconChildren="add">Adicionar</FlatOrIconButton>
  </TableCardHeader>
);

TableActions.propTypes = {
  onAddClick: PropTypes.func,
};


