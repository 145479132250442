import React, { PureComponent } from 'react';
import { Button, DialogContainer } from 'react-md';


export class DeleteDialog extends PureComponent {
  state = { visible: false, disabled: true  };

  show = () => {
    this.setState({ visible: true });
    setTimeout(() => this.setState({ disabled: false }), 3000);
  };

  hide = () => {
    this.setState({ visible: false, disabled: true });
    this.props.onHide();
  };

  confirmed = () => {
    this.props.onConfirm();
    this.hide();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.props.visble !== prevProps.visible && this.props.visible) {
      this.show();
    }
  }

  render() {
    const { disabled, visible } = this.state;
    const { name } = this.props;
    

    const actions = [];
    actions.push({ secondary: true, children: 'Cancelar', onClick: this.hide });
    actions.push(<Button flat primary onClick={this.confirmed} disabled={disabled}>Confirmar</Button>);

    return (
      <div>
        
        <DialogContainer
          id="delete-action-dialog"
          visible={visible}
          onHide={this.hide}
          actions={actions}
          title="Confirmar exclusão?"
        >
          <p>{name} será excluído permanentemente. Não será possível reverter esta operação.</p>
          <p>Você deseja prosseguir com esta operação?</p>
        </DialogContainer>
      </div>
    );
  }
}