import React, { Component } from 'react';

import { connect } from 'react-redux';
import { compose } from 'recompose';

import { getContractsUserThunk } from '../../actions';

import { ContractsTimeline } from './contracts-timeline';
import { componentEmail } from '../../utils';


class BaseContractView extends Component {


  componentDidMount() {
    const email = componentEmail(this.props);
    document.title = `Histórico de contratos - ${email}`
  }

  render() {

    const { contracts } = this.props;

    return (contracts &&
      <ContractsTimeline contracts={contracts} />
    )
  }
} 


const mapStateToProps = (state) => ({
  contracts: state.userContractsState.userContracts,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const email = componentEmail(ownProps);
  dispatch(getContractsUserThunk(email))
  return {
  }
 }


export const ContractView = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(BaseContractView);