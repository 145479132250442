import React from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { toRoundNumberString } from '../../../../utils';

// TODO: fetch from global vars
const first = "#1EB980";
const second = "#045D56";
const third = "#FF6859";
const fourth = "#FFCF44";

const style = {color: "#fff"}

const tooltipFormatter = (value) => 
  toRoundNumberString(value, 1);


const prepareData = (period, data) => {

  return [{
    "name": period,
    "Toggl (dias úteis)": data.worked - data.nwdWorked,
    "Saldo": data.balance,
    "Licença": data.license,
    "Expediente": data.reference
  }]
  
}

export const StackedBarChart = (props) => (
  <ResponsiveContainer width="100%" height={300}>
    <BarChart data={prepareData(props.period, props.data)}
          margin={{top: 20, right: 30, left: 20, bottom: 5}}>
    <CartesianGrid strokeDasharray="3 3"/>
    <XAxis dataKey="name" tick={false}/>
    <YAxis stroke="#fff"/>
    <Tooltip formatter={tooltipFormatter} />
    <Legend wrapperStyle={style} />
    <Bar dataKey="Toggl (dias úteis)" stackId="a" fill={first} />
    <Bar dataKey="Licença" stackId="a" fill={second} />
    <Bar dataKey="Expediente" stackId="b" fill={third} />
    <Bar dataKey="Saldo" stackId="c" fill={fourth} />
    </BarChart>
  </ResponsiveContainer>
);