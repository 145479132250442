import React from 'react';
import { connect } from 'react-redux';
import { auth } from '../../lib/firebase';
import { onceIsAdmin } from '../../actions';

import { AUTH_USER_SET } from '../../actions/types';

const addIsAdmin = (user, isAdmin) => {
  user["isAdmin"] = isAdmin;
  return user;
}

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    componentDidMount() {
      const { onSetAuthUser } = this.props;

      auth.onAuthStateChanged(authUser => {
        return authUser
          ? onceIsAdmin(authUser.email).then(isAdmin => onSetAuthUser(addIsAdmin(authUser, isAdmin)))
          : onSetAuthUser(null);
      });
    }

    render() {
      return (
        <Component />
      );
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    onSetAuthUser: (authUser) => dispatch({ type: AUTH_USER_SET, authUser }),
  });

  return connect(null, mapDispatchToProps)(WithAuthentication);
}

export default withAuthentication;
