import React from 'react';
import { VerticalTimeline } from 'react-vertical-timeline-component';
import { PeriodDetailElement } from "./period-detail-element";

export const PeriodTimeline = (props) => {
  // FUTURE: currently relies on object property ordering, which is not ideal. Should replace 
  // with explicit sort function
  const elements = Object.keys(props.data).reverse().map(period => 
    (<PeriodDetailElement  key={period} period={period} data={props.data[period]} />)
  );

  return (<VerticalTimeline>
    {elements}      
  </VerticalTimeline>)
}
