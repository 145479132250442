import React, { Component } from 'react';
import { Cell, Grid } from 'react-md';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getBHUserThunk } from '../../actions';
import { CurrentPeriodCard } from './current-period-card';
import { PeriodChartCard } from './period-chart-card';

import { componentEmail } from '../../utils';

class BankedHoursOverview extends Component {

  state = { email: '' };

  componentDidMount() {
    const email = componentEmail(this.props);
    this.setState({ email });
    document.title = `Banco de horas - ${email}`;
  }

  render() {
    const { bh } = this.props;
    const { email } = this.state;

    return (

    <Grid>
      <Cell size={6}>
        { !!Object.keys(bh).length && !!email &&
          <CurrentPeriodCard
            worked={bh.worked}
            nwdWorked={bh.nwdWorked}
            license={bh.license}
            reference={bh.reference}
            balance={bh.balance}
            nwdAccrued={bh.nwdAccrued}
            previousPeriod={bh.previousPeriod}
            email={email}
            data={bh}
          />
        }
      </Cell>

      <Cell size={6}>
        { !!bh && !!bh.history  && <PeriodChartCard data={bh.history} /> }
      </Cell>
    </Grid> 
  )}
}


const mapStateToProps = (state) => ({
  bh: state.userBHState.userBH,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const email = componentEmail(ownProps);
  dispatch(getBHUserThunk(email))
  return {
  }
 }


export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(BankedHoursOverview);