
/* BH actions */
export const FETCH_BH_ALL = 'FETCH_BH_ALL';
export const FETCH_BH_USER = 'FETCH_BH_USER';

/* Contracts actions */
export const FETCH_CONTRACTS_USER = 'FETCH_CONTRACTS_USER';

/* Daily actions */
export const FETCH_DAILY_USER = 'FETCH_DAILY_USER';


/* Vacations actions */
export const FETCH_VAC_ALL = 'FETCH_VAC_ALL';
export const FETCH_VAC_USER = 'FETCH_VAC_USER';

/* session actions */
export const AUTH_USER_SET = 'AUTH_USER_SET';
export const USER_DB_SET = 'USER_DB_SET';