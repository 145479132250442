import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { compose } from 'recompose';

import { Card, DataTable, Snackbar, TableHeader, TableBody, TableRow, TableColumn } from 'react-md';

import { TableActions } from './table-actions/table-actions';
import { ContractRow } from './contract-row';
import { AddContractsDialog } from './contract-dialog/add-contract-dialog';

import { DeleteDialog } from '../../components/delete-dialog';

import { getContractsUserThunk, addUserContract, deleteUserContract } from '../../actions';

import { toISODateString, componentEmail } from '../../utils';


const INITIAL_STATE = {
  addDialogVisible: false,
  deleteDialogVisible: false,
  dateToDelete: null,
  deleteMessage: '',
  email: null,
  toasts: []
};

class BaseContractEditTable extends PureComponent {

  state = INITIAL_STATE;

  componentDidMount() {

    const email = componentEmail(this.props);
    document.title = `Editar contratos - ${email}`;
    this.setState({
      email: email
    })
  }  

  showAddRowDialog = () => {
    this.setState({ addDialogVisible: true });
  };

  hideAddRowDialog = () => {
    this.setState({ addDialogVisible: false });
  };

  showDeleteDialog = (email, date) => {

    this.setState({
      deleteMessage: `Contrato de ${date} do usuário ${email}`,
      dateToDelete: date
    });
    this.setState({ deleteDialogVisible: true });
  };

  hideDeleteDialog = () => {
    this.setState({ 
      dateToDelete: null,
      deleteDialogVisible: false
    });
  };

  addContract = (date, hoursPerDay, multiplier) => {

    const dateString = toISODateString(date);

    addUserContract(this.state.email, dateString, hoursPerDay, multiplier)
    .then(() => this.setState({ dialogVisible: false }))
    .then(() => {
      if (!this.state.toasts.length) {
        const toasts = this.state.toasts.slice();
        toasts.push({
          text: 'Contrato adicionado',
          action: 'Ok'
        })
        this.setState({ toasts });
      }
    })
    .catch(err => {

      const errString = JSON.stringify(err);

      if (!this.state.toasts.length) {
        const toasts = this.state.toasts.slice();
        toasts.push({
          text: `Erro ao adicionar contrato: ${errString}`,
          action: 'Ok'
        });
        this.setState({ toasts });
      }
    })
  };

  handleDismiss = () => {
    const [, ...toasts] = this.state.toasts;
    this.setState({ toasts });
  };

  deleteContract = () => {

    deleteUserContract(this.state.email, this.state.dateToDelete)
      .then(() => {
      if (!this.state.toasts.length) {
        const toasts = this.state.toasts.slice();
        toasts.push({
          text: 'Contrato removido',
          action: 'Ok'
        })
        this.setState({ toasts, dateToDelete: null, deleteMesage: '' });
      }
    })
    .catch(err => {

      const errString = JSON.stringify(err);

      if (!this.state.toasts.length) {
        const toasts = this.state.toasts.slice();
        toasts.push({
          text: `Erro ao adicionar contrato: ${errString}`,
          action: 'Ok'
        });
        this.setState({ toasts });
      }
    })
    
  }

  showMessage = (message) => {
    if (!this.state.toasts.length) {
      const toasts = this.state.toasts.slice();
      toasts.push({
        text: message,
        action: 'Ok'
      });
      this.setState({ toasts });
    }
  }


  render() {

    const { contracts } = this.props;

    const { email, addDialogVisible, deleteDialogVisible, deleteMessage, toasts } = this.state;

    return (
      <Card tableCard>
        <TableActions
          onAddClick={this.showAddRowDialog}
        />
        <DataTable baseId="dynamic-content-contracts" onRowToggle={this.handleRowToggle}>
          <TableHeader>
            <TableRow>
              <TableColumn grow>
                Data de vigência
              </TableColumn>
              <TableColumn>
                Horas por dia
              </TableColumn>
              <TableColumn>
                Multiplicador
              </TableColumn>
              <TableColumn></TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {contracts && Object.keys(contracts).map((date) => (
              <ContractRow
                email={email}
                key={date}
                date={date}
                hoursPerDay={contracts[date].hoursPerDay}
                multiplier={contracts[date].multiplier}
                onDelete={() => this.showDeleteDialog(email, date)}
                onEdit={this.showMessage}
              />
            ))}
          </TableBody>
        </DataTable>
        <AddContractsDialog
          addContract={this.addContract}
          onHide={this.hideAddRowDialog}
          visible={addDialogVisible}
        />
        <DeleteDialog
          onConfirm={() => this.deleteContract()}
          onHide={this.hideDeleteDialog}
          visible={deleteDialogVisible}
          name={deleteMessage}
        />
        <Snackbar
          id="contract-toasts"
          toasts={toasts}
          onDismiss={this.handleDismiss}
          autohideTimeout={10000}
        />   
      </Card>
    );
  }
}


const mapStateToProps = (state) => ({
  contracts: state.userContractsState.userContracts,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const email = componentEmail(ownProps);
  dispatch(getContractsUserThunk(email))
  return {
  }
 }

export const ContractEditTable = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(BaseContractEditTable);