import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import GoogleButton from 'react-google-button'

import { doSignInWithGoogle } from '../auth';
import * as routes from '../routes';


import logo from './logo.svg';

import "./sign-in.css";


const INITIAL_STATE = {
  error: null,
};

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});


class SignIn extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    document.title = "Login"    
  }

  onLogin = (event) => {

    const {
      history,
    } = this.props;

    doSignInWithGoogle()
      .then(() => {
        history.push(routes.LANDING);
        this.setState(() => ({ ...INITIAL_STATE }));
        
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });

    event.preventDefault();
  }

  render() {
    const {
      error,
    } = this.state;

    return (<div className="login">
      <img src={logo} className="kv-logo" alt="logo" />
      <h1 className="login__heading">Sistema de RH KV</h1>
      <GoogleButton className="sign-in-button" onClick={this.onLogin} />
      { error && <p>{error.message}</p> }
    </div>)
  }
}

export default withRouter(SignIn);