// TODO refactor routes to provide titles


export const simpleTitles = {
  '/': "Sistema de RH da KV",
  '/bh': "Banco de Horas",
  '/bh/details' : "Detalhamento do trimestre atual",
  '/vacations': "Férias",
  '/dashboard/bh': "Administração do banco de horas",
  '/dashboard/vacations': "Administração das férias",
  '/user/add': "Criar usuário",
  '/user/edit/': "Editar usuário",
  '/contract/edit/': "Editar contratos",
  '/sign-in': "Sistema de RH da KV",
  '/403': "Acesso negado",
  '/about': "Sobre o sistema"
}

export const paramTitles = {
  '/bh/': "Banco de horas de ",
  '/bh/details/': "Detalhamento do trimestre atual de ",
  '/vacations/': "Férias de ",
  '/contract/': "Histórico dos contratos de ",
  '/contract/edit/': "Editar contratos de ",
  '/user/edit/': "Editar usuário ",
}


// Extracts the string after the path
export const extractParam = (url) => 
  decodeURIComponent(url.split('/').pop());


// Extracts path until the last forward - including the trailing slash
export const extractBaseUrl = (url) => 
  url.substr(0, url.lastIndexOf("/") + 1);