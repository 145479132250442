
const minElements = (container, min)  =>
  (container.length >= min);

const isNotEmpty = (container) => 
  minElements(container, 1);

export const isEmpty = (container) => !isNotEmpty(container)

const containsElement = (container, element) =>
  (container.indexOf(element) >= 0)

// TODO: refactor, it does the opposite of this
export const isInvalidEmail = (email)  =>
  (containsElement(email, '@') || !isNotEmpty(email))