import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { TextField, SelectField, SelectionControl, Card, CardTitle, CardText, CardActions, Button, FontIcon, Snackbar } from 'react-md';

import { onceGetUser, updateUser, updateVacDates, USER_DB_SET } from '../../actions';

import { isEmpty, componentEmail } from '../../utils';

import * as routes from '../../routes';

import { DatesDialog } from './dates-dialog';


import { buEnum } from '../../enum';


const selectItems = Object.keys(buEnum).map(key =>
    ({
      label: buEnum[key],
      value: key.toString()
    }))

class UserEdit extends PureComponent {

  constructor(props) {

    super();

    this.state = {
      toasts: [],
      nameError: false,
      helpVisible: false,
      isAdmin: false,
      displayName: '',
      bu: null,
      email: '',
      extraDays: 0,
      datesDialogVisible: false
    }

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleIsAdminChange = this.handleIsAdminChange.bind(this);
    this.handleBuChange = this.handleBuChange.bind(this);
    this.handleExtraDaysChange = this.handleExtraDaysChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    // TODO: get extraDays adn extra hours from state store
    return (Object.keys(nextProps.user).length > 0 && nextProps.user.constructor === Object)? {
      displayName: nextProps.user.displayName,
      bu: nextProps.user.bu.toString(),
      isAdmin: nextProps.user.role === 'admin'? true : false
    } : null;
  }

  
  componentDidMount() {
    const { onSetUser } = this.props;

    const email = componentEmail(this.props);

    this.setState({ email })

    document.title = `Editar usuário - ${email}`

    onceGetUser(email).then(snapshot =>
       onSetUser(snapshot.val()));
  }

  showDatesDialog = () => {
    this.setState({ datesDialogVisible: true });
  };

  hideDatesDialog = () => {
    this.setState({ datesDialogVisible: false });
  };


  handleSubmit = (e) => {
    e.preventDefault();

    const { email, displayName, isAdmin, bu, extraDays } = this.state;

    const role = isAdmin ? 'admin' : 'user';

    updateUser(email, displayName, parseInt(bu, 10), role, extraDays)
    .then(() => {
      if (!this.state.toasts.length) {
        const toasts = this.state.toasts.slice();
        toasts.push({
          text: 'Usuário atualizado',
          action: 'Ok',
        });
        this.setState({ toasts });
      }
    })
    .catch(err => {
      if (!this.state.toasts.length) {
        const toasts = this.state.toasts.slice();
        const errString = JSON.stringify(err)
        toasts.push({
          text: `Erro ao atualizar o usuário: ${errString}`,
          action: 'Ok',
        });
        this.setState({ toasts });
      }
    }); 
  };

  handleDismiss = () => {
    const [, ...toasts] = this.state.toasts;
    this.setState({ toasts });
  };

  toggleHelp = () => {
    this.setState({ helpVisible: !this.state.helpVisible });
  };

  handleNameChange(value, event) {
    this.setState({ displayName: value});
    this.setState({ nameError: isEmpty(value) });
  }

  handleExtraDaysChange(value, event) {
    // eslint-disable-next-line radix
    this.setState({ extraDays: parseInt(value)});
  }

  handleIsAdminChange(value, event) {
    this.setState({ isAdmin: value});
  }

  handleBuChange(bu, event) {
    this.setState({ bu })
  }


  render() {

    const {
      toasts,
      nameError,
      displayName,
      extraDays,
      bu,
      isAdmin,
      email,
      datesDialogVisible
    } = this.state;

    const isDisabled = nameError;

    const url = routes.CONTRACT_USER_EDIT_ROOT +  encodeURIComponent(email);

    return (

      <Card>
        <CardTitle title="Alterar usuário"></CardTitle>
        <form className="text-fields__application" onSubmit={this.handleSubmit}>
          <CardText>

            <TextField
              id="name"
              label="Nome"
              leftIcon={<FontIcon>person</FontIcon>}
              className="md-cell md-cell--6"
              required
              value={displayName}
              onChange={this.handleNameChange}
              error={nameError}
            />

            { bu && <SelectField
              id="bu"
              label="Gerência"
              className="md-cell md-cell--6"
              menuItems={selectItems}
              defaultValue={bu}
              value={bu}
              onChange={this.handleBuChange}
              position={SelectField.Positions.BELOW}
              required
            /> }

            <TextField
              id="extra-days"
              label="Ajuste de dias de férias"
              leftIcon={<FontIcon>cake</FontIcon>}
              className="md-cell md-cell--6"
              type="number"
              required
              value={extraDays}
              onChange={this.handleExtraDaysChange}
              error={nameError}
            />


            <SelectionControl
              id="is-admin"
              type="checkbox"
              label="É administrador do banco de horas?"
              name="admin"
              inline
              className="md-cell md-cell--6"
              checked={isAdmin}
              onChange={this.handleIsAdminChange}
            />

          </CardText>

          <CardActions className="md-cell md-cell--12">
            <Button
              flat
              secondary
              onClick={() => this.props.history.push(url) }
            >Editar contratos (BH)</Button>

            <Button
              flat
              secondary
              onClick={() => this.showDatesDialog() }
            >Editar datas (férias)</Button>

            <Button
              flat
              primary
              type="submit"
              disabled={isDisabled}
            >Salvar alterações</Button>
          </CardActions>

          <Snackbar id="user-toasts" toasts={toasts} onDismiss={this.handleDismiss} />  
        </form>
        <DatesDialog
          visible={datesDialogVisible}
          onHide={this.hideDatesDialog}
          updateDates={updateVacDates}
          email={email}
        />
      </Card>

    )
  }
}


const mapStateToProps = (state) => ({
  user: state.userState.user,
});

const mapDispatchToProps = (dispatch) => ({
  onSetUser: (user) => dispatch({ type: USER_DB_SET, user }),
});


export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps)
)(UserEdit));