import { db } from '../lib/firebase';
import { replaceDotsWithCommas } from '../utils';

import * as actions from './actions';

const BH_PREFIX = 'bh_v2';
const DAILY_PREFIX = 'daily';
const VACATIONS_PREFIX = 'vacations_v2';
const CONTRACTS_PREFIX = 'contracts';


// BH API // 

export const getBHUserThunk = (email) => 
  dispatch => {
    db.ref(`${BH_PREFIX}/${replaceDotsWithCommas(email)}`).on('value', snapshot => {
      dispatch(actions.getBHUser(snapshot.val()))
    })
  }

export const getBHAllThunk = () => 
  dispatch => {
    db.ref(`${BH_PREFIX}`).on('value', snapshot => {
      dispatch(actions.getBHAll(snapshot.val()));
    });
  }


// DAILY API //

export const getDailyUserThunk = (email) => 
  dispatch => {
    db.ref(`${DAILY_PREFIX}/${replaceDotsWithCommas(email)}`).on('value', snapshot => {
      dispatch(actions.getDailyUser(snapshot.val()))
    })
  }

// VACATIONS API //

export const getVacationsUserThunk = (email) => 
  dispatch => {
    db.ref(`${VACATIONS_PREFIX}/${replaceDotsWithCommas(email)}`).on('value', snapshot => {
      dispatch(actions.getVacationsUser(snapshot.val()))
    })
  }

export const getVacationsAllThunk = () => 
  dispatch => {
    db.ref(`${VACATIONS_PREFIX}`).on('value', snapshot => {
      dispatch(actions.getVacationsAll(snapshot.val()))
    })
  }

// CONTRACTS API //

export const getContractsUserThunk = (email) => 
  dispatch => {
    db.ref(`${CONTRACTS_PREFIX}/${replaceDotsWithCommas(email)}`).orderByKey().on('value', snapshot => {
      dispatch(actions.getContractsUser(snapshot.val()))
    })
  }