import * as firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyCEmXEoxCf1U8U4pjXKnqnIm1t6aDWtcQY',
  authDomain: 'banco-de-horas.firebaseapp.com',
  databaseURL: 'https://banco-de-horas.firebaseio.com',
  projectId: 'banco-de-horas',
  storageBucket: 'banco-de-horas.appspot.com'
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const db = firebase.database();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export {
  db,
  auth,
  provider
};