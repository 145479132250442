import React, { Component } from 'react';
import { Card, CardText, CardTitle } from 'react-md';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { getDailyUserThunk } from '../../actions';
import { HourHeatmap } from './heatmap';

import { componentEmail } from '../../utils';


import "./details.css"


// Reverses filter since the data will be reversed beforehand
const filterPositive = (value) =>
  value > 0;

const reverse = (value) => -value;

class BankedHoursDetails extends Component {

  componentDidMount() {
    const email = componentEmail(this.props);
    document.title = `Detalhes do banco de horas - ${email}`
  }

  
  
  render() {

  const { daily } = this.props;

  return (
    <Card id="details-card" className="md-cell md-cell--12">
      <CardTitle title="Detalhes Banco de Horas" />
      <CardText className="heatmap-card">
        {!!daily && !!Object.keys(daily).length && 
        <HourHeatmap data={daily} prop="worked" label="Toggl" title="Horas registradas no Toggl"/>
        }
          {!!daily && !!Object.keys(daily).length && 
          <HourHeatmap data={daily} prop="license" label="Licença" title="Horas de licença"/>
        }
        {!!daily && !!Object.keys(daily).length && 
        <HourHeatmap data={daily} prop="reference" label="Expediente" title="Expediente (horas em dias úteis)"/>
        }
        {!!daily && !!Object.keys(daily).length && 
        <HourHeatmap 
          data={daily}          
          prop="balance"
          label="Saldo Negativo"
          title="Dias com saldo negativo (Horas Toggl + Licenças < Expediente)"
          filter={filterPositive}
          transform={reverse}
        />
        }
      </CardText>
    </Card>
    );
  }
}


const mapStateToProps = (state) => ({
  daily: state.userDailyState.userDaily,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const email = componentEmail(ownProps);
  dispatch(getDailyUserThunk(email))
  return {
  }
 }


export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(BankedHoursDetails);