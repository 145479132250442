import { FETCH_VAC_USER } from '../../actions';


const INITIAL_STATE = {
  userVacations: {},
};

const applySetVacations = (state, action) => ({
  ...state,
  userVacations: action.vacations
});

function userVacationsReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case FETCH_VAC_USER: {
      return applySetVacations(state, action);
    }
    default : return state;
  }
}

export default userVacationsReducer;