

const compare = (a, b, prop) => {

  const valueA = a[prop] || ''; // handles undefined prop
  const valueB = b[prop] || ''; //handles undefined prop

  if (valueA < valueB) {
    return -1;
  }
  if (valueA > valueB) {
    return 1;
  }
  return 0;  
}

export const sortBy = (prop) => 
  (a, b) => compare(a, b, prop)

