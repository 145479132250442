import React, { PureComponent } from 'react';
import { Button, DialogContainer, Toolbar } from 'react-md';
import { PeriodTimeline } from "./period-timeline";


export class PeriodDetailsDialog extends PureComponent {
  state = { visible: false, pageX: null, pageY: null };
  show = (e) => {
    let { pageX, pageY } = e;
    if (e.changedTouches) {
      pageX = e.changedTouches[0].pageX;
      pageY = e.changedTouches[0].pageY;
    }

    this.setState({ visible: true, pageX, pageY });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  render() {
    const { visible, pageX, pageY } = this.state;

    return (
      <React.Fragment>
        <Button secondary flat onClick={this.show} aria-controls="simple-full-page-dialog">
          Ver detalhes
        </Button>
        <DialogContainer
          id="simple-full-page-dialog"
          visible={visible}
          pageX={pageX}
          pageY={pageY}
          fullPage
          onHide={this.hide}
          aria-labelledby="simple-full-page-dialog-title"
        >
          <Toolbar
            fixed
            themed
            title="Histórico detalhado"
            titleId="simple-full-page-dialog-title"
            nav={<Button icon onClick={this.hide}>close</Button>}
          />
          <section className="md-toolbar-relative">
            <PeriodTimeline data={this.props.data} />
          </section>
        </DialogContainer>
      </React.Fragment>
    );
  }
}