import React, { PureComponent } from 'react';
import { Avatar, Chip, DropdownMenu } from 'react-md';
import { doSignOut } from "../auth";

import { Redirect } from 'react-router';


const safeFirstChar = (word) => 
  word ? word.charAt(0) : "A";

export class UserAvatar extends PureComponent {

  constructor() {
    super();

    this.state = { redirectToContracts: false, redirectToAbout: false }
    this.redirectToContracts = this.redirectToContracts.bind(this);
    this.redirectToAbout = this.redirectToAbout.bind(this);
  }  

  redirectToContracts() {
    this.setState({ redirectToContracts: true })
  }

  redirectToAbout() {
    this.setState({ redirectToAbout: true })
  }

  renderRedirectAbout() {
   
    if (this.state.redirectToAbout) {
      return <Redirect push to={`/about`} />;
    }
  }

  renderRedirectContracts() {
    const { authUser } = this.props;
    if (this.state.redirectToContracts) {   
      return <Redirect push to={`/contract/${authUser.email}`} />;
    }
  }

  componentDidUpdate() {
    this.setState({ redirectToContracts: false });
    this.setState({ redirectToAbout: false });
  }


  render() {

    const { authUser } = this.props;
    

    return (
      <React.Fragment>
        {this.renderRedirectAbout()}
        {this.renderRedirectContracts()}
        <DropdownMenu
          id="account-menu"
          menuItems={[
            {
              primaryText: 'Histórico de contratos',
              onClick: this.redirectToContracts,
              disabled: !authUser
            },
            {
              primaryText: 'Sobre o sistema',
              onClick: this.redirectToAbout,
              disabled: !authUser
            },
            {
              primaryText:'Sair',
              onClick: doSignOut
            }
          ]}
          className="md-btn--toolbar"
        >
          <Chip  label={authUser ? authUser.displayName : "Anonymous"} avatar={
            authUser && authUser.photoURL ? 
              (<Avatar src={authUser.photoURL} role="presentation" />) :
              (<Avatar random>{safeFirstChar(authUser.displayName)}</Avatar>)
          }/>
        </DropdownMenu>
      </React.Fragment>
     )
  }
}