import React from 'react';
import { Card, CardActions, CardText, CardTitle } from 'react-md';
import { PeriodBarChart } from './bar-chart';
import { PeriodDetailsDialog } from './details';

export const PeriodChartCard = (props) => (
  <Card className="md-block-centered">
    <CardTitle title="Resumo Banco de Horas" />
    <CardText>
      <PeriodBarChart {...props} />
    </CardText>
    <CardActions className="md-divider-border md-divider-border--top">
      <PeriodDetailsDialog {...props} />
    </CardActions>
  </Card>
)